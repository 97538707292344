@import "./themes.scss";
@import "./base.scss";

.content {
 // padding-bottom: 2.5rem;
}

.main-container{
  height:100vh;
  padding-top: 5%;
}

.react-bootstrap-table table {
  table-layout: auto !important;
  position:relative !important;
}
.react-bootstrap-table{
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 768px) {
  .main-container{
    margin-left:0 !important;
  }
}

#app {
  background-image: url('../FOI/images/BC.bmp');
}
