@charset "UTF-8";

@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css);
@import url(https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css);
@import url(https://cdn.jsdelivr.net/npm/formiojs@4.13.1/dist/formio.full.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);

@font-face {
  font-family: "BCSans-Bold";
  src: url("./assets/FOI/fonts/BCSans-Bold.woff") format('woff');
}

@font-face {
  font-family: "BCSans-Regular";
  src: url("./assets/FOI/fonts/BCSans-Regular.woff") format('woff');
}


$white: #fff;
$grey: #6c757d;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #373a3c;
$gray-900: #212529;
$black: #000;

$blue: #2780e3;
$indigo: #6610f2;
$purple: #613d7c;
$pink: #e83e8c;
$red: #ff0039;
$orange: #f2661f;
$yellow: #fcba19;
$green: #3fb618;
$teal: #20c997;
$cyan: #9954bb;
$lightblue: #4d61fc;

$primary: $blue;
$secondary: $grey;
$success: $green;
$info: $lightblue;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  max-width: 100vw;
}

.container {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 2%;
  // height: 100%;
}

div.container>div.container {
  padding-bottom: 2%;
  height: 80vh;
}

.overflow-x-auto {
  overflow-x: auto;
  overflow-y: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

.task-container {
  height: 100% !important;
}

.logo {
  height: 1.9em;
}

h4 {
  font-size: 1.2rem;
}

#main {
  /* margin-top: 2px;*/
  height: 70vh;
  //max-height: 85vh;
  padding-bottom: 1.5rem;
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  font-size: 16px;
  font-family: Nunito Sans, SemiBold;
}

.loader-container {
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
}

.loader {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.input-group {
  .input-group-addon {
    position: absolute;
    right: 5px;
    top: 7px;
  }

  .radio-inline {
    margin-left: 20px;

    label {
      margin-right: 2rem;
    }
  }
}

.well {
  border: 1px solid #eeeeee;
  padding: 1rem;
  background-color: #f8f8f8bf;
}

.formio-component-panel {
  border: 1px solid#eeeeee;

  .panel-heading {
    background-color: #eeeeee;
    padding: 10px 0 10px 10px;

    .panel-title i {
      font-size: 12px;
    }
  }

  .panel-body {
    padding: 1.5rem 2rem 1.5rem 2rem;
  }
}

.page-item.active .page-link {
  background-color: #036 !important;
  border-color: #56595d !important;
}

h5,
.h5 {
  font-size: 16px !important;
}

.bg-default {
  background-color: #38598a;
  border: #38598a;
  color: white !important;
}

.text-muted {
  color: #868e96 !important;
}

i.fa.fa-question-circle.text-muted {
  color: #2e96ff !important;
}

.panel-heading {
  background-color: #38598a;
  border: #38598a;
  color: white !important;
  padding: 0.75rem 1.25rem;
  margin-bottom: 8px;
}

.formio-error-wrapper,
.has-error {
  color: red;
  background-color: #fff;
  border-color: red;

  label {
    color: red;
  }
}

.formio-errors .error {
  color: red;
}

.is-invalid {
  background-color: #ffd1d1;
}

.nav-dropdown {
  .dropdown-menu {
    width: 15rem;
    height: 14rem;
    padding: 15px 20px 0 20px;
    right: 0;
    top: 2rem;
    left: auto;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .dropdown-toggle {
    color: white !important;

    a {
      text-decoration: none;
    }
  }

  .userIcon {
    padding: 0 0 0 0;
    font-size: 2rem;
    float: right;
    color: #1264b6e0;
  }
}

.nav-dropdown :hover {
  text-decoration: none !important;
}

.nav-custom-tab {
  padding-left: 60px !important;
}

.main-nav {
  text-decoration: none !important;
  color: $white !important;
  // font-size: 20px !important;
}

.nav-user-role {
  color: #003366;
  font-size: 15px;
  font-weight: bold;
  padding: 0 0;
}

.nav-user-email {
  font-size: 14px;
  padding: 0 0;
  color: #373a3c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-user-name {
  font-size: 20px;
  padding: 0 0;
  font-weight: 600;
  color: #373a3c;
}

.nav-logout {
  cursor: pointer;
  padding: 0 0;
  font-size: 16px;
}

.nav-tabs .nav-link.active {
  color: #4d4d4d;
  background-color: #f5f5f5;
  border-color: #f5f5f5 !important;
}

.nav-link {
  a {
    color: white;
  }
}

.active {
  background-color: transparent;
}

.active-tab {
  background-color: transparent;
  filter: invert(80%) sepia(81%) saturate(4581%) hue-rotate(345deg) brightness(95%) contrast(101%);
}

.inactive-tab {
  filter: brightness(0) invert(1);
}

.active-tab-dropdown {
  background-color: transparent;
  filter: invert(80%) sepia(81%) saturate(4581%) hue-rotate(345deg) brightness(95%) contrast(101%);
}

.inactive-tab-dropdown>#dashboard-dropdown {
  background-color: transparent;
  filter: brightness(0) invert(1);
}

.active-tab-dropdown>#task-dropdown {
  background-color: transparent;
  filter: invert(80%) sepia(81%) saturate(4581%) hue-rotate(345deg) brightness(95%) contrast(101%);
}

.dropdown-item:hover {
  filter: none;
  background-color: #e9ecef;
}

#task-dropdown::after {
  color: #fcba19;
}

#dashboard-dropdown::after {
  color: #fcba19;
}

.dashboard-dropdown-options {
  padding-right: 5px;
  margin-left: -5px;
}

.dropdown-option-selected {
  background-color: #e9ecef !important;
  color: #212529 !important;
}

.inactive-tab:hover {
  color: #fcba19;
  cursor: pointer;
}

#username:hover {
  color: #fcba19;
  cursor: pointer;
}

.selected-tag {
  color: $lightblue !important;
  border-bottom: 1px solid $lightblue;
  background-color: transparent;
}

.lbl-logout {
  font-size: 16px;
  color: #373a3c;
  margin-left: 10px;
  cursor: pointer;
}

// .dropdown-menu {
//   height: 17em;
//   overflow-y: scroll;
// }
.dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
}

.taskDropdown {
  .dropdown-menu::before {
    left: 10%;
    right: 100%;
  }

  .dashboard-icon-dropdown {
    margin-bottom: 5px;
  }

  .task-dropdown-icon {
    margin-bottom: 1px;
    color: white;
  }

  .applications-icon-header {
    width: 23px;
    height: 23px;
    margin-bottom: 5px;
  }

  .dropdown-menu::after {
    left: 10%;
    right: 100%;
  }
}

.custom-profile {
  position: absolute;
  margin-left: 220px;
  padding-bottom: 10px;
  z-index: 10;
}

.d-md-none {

  .dropup,
  .dropright,
  .dropdown,
  .dropleft {
    position: initial;
  }

  .nav-dropdown .dropdown-menu {
    position: absolute;
  }

  .dropdown-menu:before {
    position: absolute;
    top: -6px;
    right: 4px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: "";
  }

  .dropdown-menu:after {
    position: absolute;
    top: -5px;
    right: 5px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: "";
  }
}

.lbl-app-nanme {
  font-size: 20px;
  color: #ffff;
  margin-bottom: 0;
  font-weight: bold;
  margin-left: 20px;
}

.app-name {
  color: $yellow;
  margin: 0;
}

.nav-icons {
  vertical-align: text-top;
  margin-right: 3px;
}

.nav-dropdown .dropdown-menu {
  height: auto !important;
}

.table-bordered thead th,
.table-bordered thead td {
  background-color: #f2f2f2 !important;
}

.navbar-dark.navbar-toggler {
  border-color: white;
}

.main-header {
  margin-left: 15px !important;
  display: flex;
  margin-top: 10px;
  margin-bottom: 25px;
  height: 30px;
  width: 100%;
}

.back-icon {
  display: flex;
}

.sub-container {
  margin-left: 15px;
}

.task-head {
  font-weight: bold;
  font-size: 30px;
  margin-left: 10px !important;
  //display: flex;
}

.icon-wp-forms {
  height: 30px;
}

.forms-text {
  vertical-align: bottom;
  margin-left: 10px;
  margin-bottom: 0px;
}

.task-head-icons {
  height: 30px;
  width: 30px;
}

.task-head-details {
  font-weight: bold;
  font-size: 30px;
  margin-left: 10px !important;
}

.icon-wp-forms {
  height: 30px;
}

.forms-text {
  vertical-align: middle;
  margin-left: 10px;
}

.btn-right {
  float: right;
  margin-left: auto;
}

.btn-center {
  float: right;
  margin-left: auto;
}

.btn.btn-link:focus,
.btn.btn-link.focus {
  box-shadow: none;
}

.div-center {
  align-self: center;
}

.tooltip-inner {
  color: black !important;
  background-color: white !important;
  border: 0.5px solid !important;
  text-align: left;
}

.navbar-brand {
  align-items: center;
}

.navbar-brand .img-fluid,
.navbar-brand .img-thumbnail {
  max-width: 100%;
  height: 100%;
}

select option:hover {
  box-shadow: 0 0 10px 100px #000 inset;
}

.modal-dialog {
  margin: 8.75rem auto !important;
}

#main {
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

#sidebar {
  background: #fff;
}

// .content {
//   background-color: #f8f8f8;
// }

#sidebar ul li.active>a,
#sidebar a[aria-expanded="true"] {
  background: #fff;
}

header nav {
  border-bottom: #fff;
}

.topheading-border-bottom {
  z-index: 2000;
  border-bottom: 2px solid #fcba19 !important;
  padding: 0 0.5rem !important;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  background: #003366;
}

.rounded-circle {
  border-radius: 50% !important;
  border: 2px solid lightgrey;
  height: 40px;
  width: 40px;
}

.custom-app-name {
  font-size: 30px;
  font-weight: 600;
  /*margin-left: -15px!important;*/
  color: white;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.css-79elbk {
  // position: none;
}

.classApplicationId {
  width: 15%;
}

.classApplicationName {
  width: 20%;
}

.navbar-toggler-icon {
  display: none;
}

@media screen and (min-width: 0px) and (max-width: 575px) {
  .mobileh1content {
    padding-top: 1%;
    font-family: BCSans-Regular, sans-serif !important;
    font-size: 16px;
    font-weight: 600;
  }

  .btn-outline-bcblue {
    font-size: 14px;
    padding: 4px;
  }

  .mobilesubcontent {
    font-size: 15px;
    text-align: left;
  }

  .mobilebreak {
    display: none;
  }

  .mobileh3content {
    font-size: 16px;
    font-weight: 600;
  }

  #feedescheader,
  #estimatedfeeheader,
  #feedescription,
  #estimatedfeeheader,
  #feevalue {
    display: none;
  }

  #feedescriptionmobile {
    display: block;

    b,
    strong {
      font-weight: bold !important;
      font-size: 18px;
    }
  }

  #mainrequestnumber,
  .mainrequestnumber {
    display: none;
  }

  #mainrequestnumbermobile,
  .mainrequestnumbermobile {
    display: block;
    padding-top: 5%;
    font-size: 14px
  }

  div[data-testid="wrapper"] {
    padding-right: 0px;
    padding-left: 0px;
  }

  .foi-text-align {
    text-align: left;

    p {
      text-align: left !important;
    }
  }

  div[ref="nested-payOnlinePage1"]
  {
    .card-body {
      height: 700px;
    }
  }

  

  /* show it on small screens */
}


@media screen and (min-width: 576px) {
  #feedescriptionmobile {
    display: none;
  }

  #mainrequestnumbermobile,
  .mainrequestnumbermobile {
    display: none;
  }

  #mainrequestnumber,
  .mainrequestnumber {
    padding-top: 0.5%;
    display: block;
  }

  .foi-text-align {
    text-align: center;
  }

  .card-body {
    min-height: 500px;
    height: auto;
    padding-top: 3%;
  }

 

  .mainrequestnumber {
    b,strong {
      font-weight: 800;
    }
  }

  #btnpayonline {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  #feedescription , #feevalue {

    b,
    strong, span {
      font-weight: bold !important;
      font-size: 18px;
    }
  }

  /* hide it elsewhere */
}