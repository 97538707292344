@import "./themes.scss";
.text-white {
  color: $white;
}

.bg-white {
  background-color: $white;
}
.bg-yellow {
  background-color: $yellow !important;
}
.text-yellow {
  color: $yellow !important;
}

.bg-purple {
  background-color: $purple !important;
}
.text-purple {
  color: $purple !important;
}

.text-green {
  color: $green !important;
}

.bg-green {
  background-color: $green !important;
}

.text-blue {
  color: $dark-blue;
}

.click-element {
  cursor: pointer;
}
.down-size{
  width: 13px;
  height: 15px;
}
.click-element[dat-title]:hover:after,i[dat-title]:hover:after {
  font-size: 12px;
  font-weight: 300;
  content: attr(dat-title);
  padding: 4px 8px;
  color: rgb(255, 255, 255);
  position: absolute;
  background-color: black;
  //left: 1rem;
  white-space: nowrap;
  z-index: 1;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #eeeeee),color-stop(1, #cccccc));
  background-image: -webkit-linear-gradient(top, #eeeeee, #cccccc);
  background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
  background-image: -ms-linear-gradient(top, #eeeeee, #cccccc);
  background-image: -o-linear-gradient(top, #eeeeee, #cccccc);
}
.operator-container{

}
.operator-box-container{
  display: inline-block;
}
.operator-selector{
  border:none;
  -o-appearance: none;
   -ms-appearance: none;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
  //  width: 45px;
  padding: 0 0 0 3px;
  padding-right: 12px;
  background-color: #f2dede;
  outline: none;
  color: #155cb5;
}
.operator-selector select {
  display: none; /*hide original SELECT element:*/
}
.selection-item{
  background-color: white;
}
//TODO Remove this when Semantic UI css is removed. Semantic UI css override formio css tooltip
[data-tooltip]:before {
  visibility: unset !important;
  position: initial !important;
  box-shadow: none !important;
  opacity: 1 !important;
  cursor:pointer !important;
}
[data-tooltip]:after {
  opacity: 0 !important;
}
.fa-question-circle:before {
  content: "\f059" !important;
}
